import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Swal from "sweetalert2";
import downarrow from "../../assets/img/downarrow.png";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import Navbar from "../../components/NavBar";
import apiroutes from "../../services/apiroutes";
import { requestBooking } from "../../services/apiservice";
import { getAuth } from "../../services/auth";

export const FlutterCallBackComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [stateSuccessful, setStateSuccessful] = useState({});
  const [failureState, setFailureState] = useState(false);
  const [seatNumber, setSeatNumber] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const reference = urlParams.get("tx_ref");
  const token = getAuth();
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);

  let routeName = stateSuccessful?.Route || "loading... ==> loading...";
  const routeFields = routeName.split("==>");

  useEffect(() => {
    let data = localStorage.getItem("transEmailAmount");
    data = JSON.parse(data);
    setSeatNumber(data?.seats);
    setDetails(data);
    const confirmPayment = {
      email: data?.email,
      amount: data?.amount,
      RefCode: data?.refCode,
      // RefCode: reference,
    };

    requestBooking(apiroutes.ConfirmFlutterPayment(data?.refCode), "get", null, token)
      .then((res) => {
        if (res.data.code == "1") {
         
          setLoader(false);
          if(res?.data?.data.bookingReference === null) {
          setFailureState(true);
          localStorage.removeItem("userSelect");
          localStorage.removeItem("allTripDetails");
          localStorage.removeItem("selectedBusData");
          localStorage.removeItem("selectedSeats");
          localStorage.removeItem("selectedSeatsReturn");
          localStorage.removeItem("selectedReturnBusData");
          }
          setStateSuccessful(res?.data?.data);
          localStorage.removeItem("userSelect");
          localStorage.removeItem("allTripDetails");
          localStorage.removeItem("selectedBusData");
          localStorage.removeItem("selectedSeats");
          localStorage.removeItem("selectedSeatsReturn");
          localStorage.removeItem("selectedReturnBusData");
        } else {
          setLoader(false);
          setFailureState(true);
          localStorage.removeItem("userSelect");
          localStorage.removeItem("allTripDetails");
          localStorage.removeItem("selectedBusData");
          localStorage.removeItem("selectedSeats");
          localStorage.removeItem("selectedSeatsReturn");
          localStorage.removeItem("selectedReturnBusData");
        }
      })
      .catch((err) => {
        setLoader(false);
        setFailureState(true);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text:  err.response.status,
          icon: "error",
        });
      });
  }, [reference, token]);

  const getBookingStatus = (e) => {
    e.preventDefault();
    // setError("");
    // setResponseMsg("Processing");
    setLoading(true);
    requestBooking(apiroutes.BookingStatus(details?.refCode), "get", null, token)
      // request(apiroutes.BookingStatus(refCode), "get", null, token)
      .then((res) => {
        if (
          res.data?.Object.length === 0 ||
          res.data.Object?.refCode === null
        ) {
          setLoading(false);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Booking Status not found for this particular Booking Reference ${details?.refCode}, Please try again.`,
            icon: "error",
          });
          setTimeout(() => window.location.reload(), 1000);
        } else {
          // setResponseMsg(null);
          setLoading(false);
          // setError(false);
          localStorage.setItem(
            "allBookingStatus",
            JSON.stringify(res.data.data)
          );
          history.push("/bookingStatus");
        }
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text:  err.response.status,
          icon: "error",
        });
      });
  };

  var newDate = new Date(stateSuccessful?.DepartureDate || "1/1/2000");
  var yearOne = newDate.getFullYear();
  var monthOne = newDate.getMonth() + 1; //getMonth is zero based;

  var dayOne = newDate.getDate();
  let formatted = dayOne + "-" + monthOne + "-" + yearOne;

  return (
    <div>
      {loader === false ? (
        <>
          <Navbar />
          <section className="suc-err-page">
            {failureState ? (
              <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-10 col-md-8 col-lg-6">
                    <div className="ticket-card">
                      <h1 className="route-card-h1">
                        Your Booking was Cancelled!!!
                      </h1>
                      <div className="text-center">
                        <button
                          className="download-btn"
                          onClick={() => {
                            history.push("/");
                          }}
                        >
                          Book Again &nbsp; <img src={downarrow} alt="" />
                        </button>
                      </div>
                    </div>

                    {/* <h3>Reference Code: {reference}</h3> */}
                  </div>
                </div>
              </div>
            ) : (
              // <div className="container h-100">
              //   <div className="row h-100 justify-content-center align-items-center">
              //     <div className="col-10 col-md-8 col-lg-6">
              //       <div className="ticket-card">
              //         <h1 className="route-card-h1" style={{ color: "#E21D00" }}>
              //           Failed Booking
              //         </h1>
              //         <div className="route-card">
              //           <div className="d-flex">
              //             <p className="left-par">
              //               {routeFields[0]}
              //               <br />
              //               {stateSuccessful.DepartureTime}
              //             </p>
              //             <p className="left-par ml-auto text-right">
              //               {routeFields[1]}
              //               <br />
              //               {stateSuccessful.DepartureTime}
              //             </p>
              //           </div>
              //         </div>
              //         <div className="route-card-red">
              //           <div className="d-flex">
              //             <p className="left-par">
              //               Departure date
              //               <br />
              //               {formatted}
              //             </p>
              //             <p className="left-par ml-auto text-right">
              //               Departure Time
              //               <br />
              //               {stateSuccessful.DepartureTime}
              //             </p>
              //           </div>
              //         </div>
              //         <div className="route-card-white-r">
              //           <div className="d-flex">
              //             <p className="left-par">
              //               Passenger
              //               <br />
              //               <span>{details.mainName}</span>
              //               <br />
              //               {!details.otherNames ||
              //               details.otherNames.length === 0 ? (
              //                 "&nbsp;"
              //               ) : (
              //                 <>
              //                   {details.otherNames.map((item, i) => (
              //                     <>
              //                       <span key={i}>{item.fullName}</span>
              //                       <br />
              //                     </>
              //                   ))}
              //                 </>
              //               )}
              //             </p>
              //             <p className="left-par ml-auto text-right">
              //               Seats
              //               <br />
              //               {seatNumber.toString()}
              //             </p>
              //           </div>
              //         </div>
              //         <div className="text-center">
              //           <button
              //             className="download-btn"
              //             style={{ backgroundColor: "#E21D00" }}
              //             onClick={() => {
              //               history.push("/");
              //             }}
              //           >
              //             Book Again{" "}
              //           </button>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </div>
              <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-10 col-md-8 col-lg-6">
                    <div className="ticket-card">
                      <h1 className="route-card-h1">
                        Your Ticket ({details?.refCode})
                      </h1>
                      <div className="route-card">
                        <div className="d-flex">
                          <p className="left-par">
                            {routeFields[0]}
                            <br />
                            {stateSuccessful?.departureTime}
                          </p>
                          <p className="left-par ml-auto text-right">
                            {routeFields[1]}
                            <br />
                            {stateSuccessful?.departureTime}
                          </p>
                        </div>
                      </div>
                      <div className="route-card-blue">
                        <div className="d-flex">
                          <p className="left-par">
                            Departure date
                            <br />
                            {formatted}
                          </p>
                          <p className="left-par ml-auto text-right">
                            Departure Time
                            <br />
                            {stateSuccessful?.departureTime}
                          </p>
                        </div>
                      </div>
                      <div className="route-card-white">
                        <div className="d-flex">
                          <p className="left-par">
                            Passenger
                            <br />
                            <span>{details?.mainName}</span>
                            <br />
                            {!details?.otherNames ||
                            details?.otherNames.length === 0 ? (
                              "&nbsp;"
                            ) : (
                              <>
                                {details?.otherNames.map((item, i) => (
                                  <>
                                    <span key={i}>{item?.fullName}</span>
                                    <br />
                                  </>
                                ))}
                              </>
                            )}
                          </p>
                          <p className="left-par ml-auto text-right">
                            Seats
                            <br />
                            {seatNumber?.toString()}
                          </p>
                        </div>
                      </div>
                      {/* <div className="route-card-white-b">
                     <div className="d-flex">
                       <p className="left-par">
                       Passenger
                         <br />
                         {stateSuccessful.DepartureDate}
                       </p>
                       <p className="left-par ml-auto text-right">
                       Seats
                         <br />
                         {stateSuccessful.SeatNumber}
                       </p>
                     </div>
                   </div> */}
                      <div className="text-center">
                        {/* <button
                      className="download-btn"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Go Home &nbsp; <img src={downarrow} alt="" />
                    </button> */}
                        <Button
                          handleButtonClick={getBookingStatus}
                          // handleButtonClick={checkBookingStatus}
                          text={
                            loading ? (
                              <Loader dark={false} />
                            ) : (
                              "Check Booking Status"
                            )
                          }
                          type="button"
                          btnstyle={{
                            backgroundColor: "#E21D00",
                            margin: "20px 0px",
                            width: "70%",
                          }}
                        />
                      </div>
                    </div>

                    {/* <h3>Reference Code: {reference}</h3> */}
                  </div>
                </div>
              </div>
            )}
          </section>

          <Footer />
        </>
      ) : (
        <>
          {/* <p>Loading</p> */}
          <div
            style={{ background: "#67656500", paddingTop: "400px" }}
            className="text-center"
          >
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default FlutterCallBackComponent;
