import React, { useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Expire from "../../components/Expire";
import InputField from "../../components/InputField";
import Loader from "../../components/Loader";
import apiroutes from "../../services/apiroutes";
import { requestBooking } from "../../services/apiservice";
import { getAuth } from "../../services/auth";

export const BookStatus = ({ user = false }) => {
  const token = getAuth("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [refCode, setRefCode] = useState();
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");

  const getBookingStatus = (e) => {
    e.preventDefault();
    setError("");
    setResponseMsg("Processing");
    setLoading(true);

    requestBooking(apiroutes.BookingStatus(refCode), "get", null, token)
      .then((res) => {
        if (res.data.code === 1 && res.data?.data?.userData?.length > 0) {
          setResponseMsg(null);
          setLoading(false);
          setError(false);
          localStorage.setItem(
            "allBookingStatus",
            JSON.stringify(res.data.data?.userData)
          );
          history.push("/bookingStatus");
        } else {
          setLoading(false);
          setResponseMsg(null);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Booking Status not found for this particular Booking Reference ${refCode}, Please try again.`,
            icon: "error",
          });
          // setTimeout(() => window.location.reload(), 1000);
        }
      })
      .catch((err) => {
        // if(err.data.Message === "Authorization has been denied for this request") {
        //   alert("Hi")
        // }
        // setError(err);
        setLoading(false);
        // setResponseMsg(null);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err.response.status,
          icon: "error",
        });
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {error && (
            <Expire delay={3000}>
              <Alert className="alert text-center alert-danger" text={error} />
            </Expire>
          )}
          {responseMsg && !error && (
            <Expire delay={3000}>
              <Alert
                className="alert text-center alert-primary"
                text={responseMsg}
              />
            </Expire>
          )}
          <label
            className="label-auth"
            style={{ color: user ? "black" : "white" }}
          >
            Check booking status
          </label>
          <br />
          <br />
          {user == true ? (
            <InputField
              type="text"
              placeholder="Enter Reference Code"
              onChangeMethod={(e) => setRefCode(e.target.value)}
              value={refCode}
              customStyles={{ color: "black" }}
            />
          ) : (
            <InputField
              type="text"
              placeholder="Enter Reference Code"
              onChangeMethod={(e) => setRefCode(e.target.value)}
              value={refCode}
              customStyles={{ color: "white" }}
            />
          )}
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <Button
            text={loading ? <Loader dark={false} /> : "Proceed"}
            handleButtonClick={getBookingStatus}
            //handleButtonClick={() => {onclick(value)}}
            type="button"
            btnstyle={{
              background: "#E21D00",
              color: "white",
              margin: "20px 0px",
              // fontFamily: "Euclid Circular B",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
            }}
            disabled={!refCode}
          />
        </div>
      </div>
    </div>
  );
};
export default BookStatus;
