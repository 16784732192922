import React, { useState } from "react";
import Swal from "sweetalert2";
import Button from "../../../components/Button/index";
import Select from "../../../components/Dropdown/index";
import InputField from "../../../components/InputField/index";
import "./individual.css";




const IndividualPartner = ({ next }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [inspectLocation, setInspectLocation] = useState("");
  const [numberOfVehicles, setNumberOfVehicles] = useState("");

  const checkEmail = () => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(email)) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `Please provide a valid email address`,
        icon: "error",
      });
      return false;
    } else {
      handleEnterprise();
    }
  };



  const handleEnterprise = () => {
    if (
      !(firstName,
      lastName,
      address,
      state,
      inspectLocation,
      email,
      phone,
      numberOfVehicles)
    ) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: "All fields are required.",
        icon: "error",
      });
    } else if (phone.length < 11 || phone.length > 11) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: "Only Nigerian numbers are allowed. Please provide a Nigerian number.",
        icon: "error",
      });
    } else {
    const individualPartner_Details = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      partnerType: 1,
      address: address,
      state: state,
      InspectionLocation: inspectLocation,
      NumberOfVehicles: numberOfVehicles,
    };
    localStorage.setItem("individualPartner_Details", JSON.stringify(individualPartner_Details))
    next()
  }
  };

  const stateOptions = [
    "FCT",
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ];
  const optionState = stateOptions.map((x) => ({ label: x, value: x }));

  
  const inspectLocationOptions = ["Jibowu - Lagos", "Ajah - Lagos", "Iyana Ipaja - Lagos", "Asaba - Delta", "Utako - FCT", "Uselu - Edo"]
  const optionsInspectLocation = inspectLocationOptions.map((x) => ({ label: x, value: x }));

  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "white" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "black" : "black",
    }),
  };


  return (
    <div>
      <section>
        <div className="contactform">
          <div className="contactformholder">
            <div className="partnerform">
              <h1>Contact Details</h1>
              <p>Kindly provide the details below</p>

              <div>
                <label htmlFor="firstname">First Name</label>
                <InputField
                  type="text"
                  placeholder="Enter First Name"
                  onChangeMethod={(e) => setFirstName(e.target.value)}
                />
                <label htmlFor="lastname">Last Name</label>
                <InputField
                  type="text"
                  placeholder="Enter Last Name"
                  onChangeMethod={(e) => setLastName(e.target.value)}
                />
                <label htmlFor="phonenumber">Phone Number</label>
                <InputField
                  type="tel"
                  placeholder="080X XXX XXXX"
                  onChangeMethod={(e) => setPhone(e.target.value)}
                />
                <label htmlFor="email">Email Address</label>
                <InputField
                  type="email"
                  placeholder="Example@gmail.com"
                  onChangeMethod={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="Businessaddress">Business Address</label>
                <InputField
                  type="text"
                  placeholder="Enter Address"
                  onChangeMethod={(e) => setAddress(e.target.value)}
                />
                <label htmlFor="state">Select State</label>
                <Select
                value={state}
                  options={optionState}
                  handleChange={(e) => setState(e.value)}
                  placeholder="Select State"
                  styles={colorStyles}
                />
                <label htmlFor="state">Select Preferred Inspect Location</label>
                <Select
                value={inspectLocation}
                  options={optionsInspectLocation}
                  handleChange={(e) => setInspectLocation(e.value)}
                  placeholder="Select Inspection State"
                  styles={colorStyles}
                />
                <label htmlFor="state">Number Of Vehicles</label>
                <InputField
                  type="tel"
                  placeholder="Enter No. Of Vehicle"
                  onChangeMethod={(e) => setNumberOfVehicles(e.target.value)}
                />
                <Button
                  handleButtonClick={checkEmail}
                  text="Next"
                  type="button"
                  btnstyle={{ backgroundColor: "#E21D00", margin: "20px 0px" }}
                  disabled={!(phone && email && firstName && lastName && address && state && inspectLocation && numberOfVehicles)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IndividualPartner;
