import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/index";
import Navbar from "../../components/NavBar/index";
import "./route-prices.css";
import SelectComponent from "../../components/Dropdown";
import Button from "../../components/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAuth } from "../../services/auth";
import { requestSetting, requestBooking } from "../../services/apiservice";
import apiroutes from "../../services/apiroutes";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import hiaceBus from "../../assets/img/hiace22.png";
import jetBus from "../../assets/img/jetmover.png";
import premium from "../../assets/img/premium.png";
import proBus from "../../assets/img/proBus.png";

const RoutePrices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getDepartureTerminal();
  }, []);
  const history = useHistory();
  const nf = new Intl.NumberFormat();
  const [pageLoading, setPageLoading] = useState(false);
  const [allRouteFairPrices, setAllRouteFairPrices] = useState([]);
  const [departureTerminal, setDepartureTerminal] = useState([]);
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [loadingLoading, setLoadingLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const optionTerminal = departureTerminal?.map((x) => ({
    label: x.terminalName,
    value: x.id,
  }));

  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "black",
      background: "white",
    }),

    menu: (provided, state) => ({
      ...provided,
      // borderBottom: '1px dotted pink',
      // color: state.isSelected ? 'red' : 'blue',
      // padding: 20,
      background: "white",
    }),

    singleValue: () => {
      const color = "black";

      return { color };
    },
  };

  const getDepartureTerminalNameSplit = (terminal) => {
    const nameSplit = terminal.split("==>");
    return nameSplit[0];
  };

  const getArrivalTerminalNameSplit = (terminal) => {
    const nameSplit = terminal.split("==>");
    return nameSplit[1];
  };

  const getDepartureTerminal = () => {
    setLoadingLoading(true);
    requestSetting(apiroutes.DepartureTerminals(), "get", null, getAuth())
      .then((res) => {
        setLoadingLoading(false);
        setDepartureTerminal(res.data?.data?.userData);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setLoadingLoading(false);
          Swal.fire({
            showConfirmButton: false,
            timer: 10000,
            text: `Error while fetching terminals., Please try again!`,
            icon: "error",
          });
          history.push("/");
        }
      });
  };
  const getRouteFairPrices = () => {
    setLoadingLoading(true);
    setPageLoading(true);
    setLoading(true);

    requestBooking(
      apiroutes.RouteFarePrices(departure, arrival),
      "get",
      null,
      getAuth()
    )
      .then((res) => {
        setLoadingLoading(false);
        setPageLoading(false);
        setLoading(false);
        setAllRouteFairPrices(res.data?.data);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setLoadingLoading(false);
          setPageLoading(false);
          setLoading(false);
          Swal.fire({
            showConfirmButton: false,
            timer: 10000,
            text: `Error while fetching terminals., Please try again!`,
            icon: "error",
          });
          history.push("/");
        }
      });
  };

  return (
    <div>
      <Navbar />
      <Loading isLoading={loadingLoading} />
      <section className="route-prices" style={{ padding: "100px 0" }}>
        <div className="container">
          <div
            className="terminals d-flex flex-wrap justify-content-between"
            style={{ overflowY: "scroll" }}
          >
            <div className="col-md-12 col-sm-12">
              <div>
                <h1>Travel Routes and Prices</h1>
                <h6>Search route:</h6>
                <br />
                <div className="search-section d-flex flex-wrap justify-content-between align-items-end mb-5">
                  <div className="col-md-5">
                    <label className="label-auth">Travelling From</label>
                    <SelectComponent
                      options={optionTerminal}
                      handleChange={(e) => {
                        setDeparture(e.value);
                      }}
                      value={departure}
                      styles={colorStyles}
                      placeholder="Departure Terminal"
                      id="departure"
                    />
                  </div>
                  <div className="col-md-5">
                    <label className="label-auth">Travelling To</label>
                    <SelectComponent
                      options={optionTerminal}
                      handleChange={(e) => {
                        setArrival(e.value);
                      }}
                      value={arrival}
                      styles={colorStyles}
                      placeholder="Arrival Terminal"
                      id="arrival"
                    />
                  </div>
                  <div className="col-md-2">
                    <Button
                      text={
                        loading ? (
                          <span
                            style={{
                              display: "inline-block",
                              transform: "translateX(-10px)",
                            }}
                          >
                            Loading...
                          </span>
                        ) : (
                          "Search"
                        )
                      }
                      handleButtonClick={() => getRouteFairPrices()}
                      type="button"
                      btnstyle={{
                        backgroundColor: "red",
                        color: "white",
                        padding: "0px 25px",
                      }}
                      disabled={!(departure && arrival) || loading}
                    />
                  </div>
                </div>

                <section id="open-positions">
                  {pageLoading ? (
                    <div className="text-center">
                      <div className="lds-default">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <section className="bus-select pb-5">
                        <div className="">
                          <>
                            {!allRouteFairPrices?.length ? (
                              <div className="text-center">
                                <div className="text-center">
                                  No price found, Check a new routes.
                                </div>
                                {/* <Button
                                  text="Book Again"
                                  handleButtonClick={() => history.push("/")}
                                  type="button"
                                  btnstyle={{
                                    background: "#56CCF2",
                                    color: "white",
                                    width: "150px",
                                    margin: "20px 0",
                                  }}
                                /> */}
                              </div>
                            ) : (
                              <div className="" style={{display: "flex", justifyContent: "space-around", flexWrap: "wrap"}}>
                                {allRouteFairPrices?.map((item, index) => (
                                  <div
                                    className="row bus-card col-md-5 mr-3 mb-4"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      backgroundColor:
                                        "rgba(86, 204, 242, 0.3)",
                                    }}
                                    key={index}
                                  >
                                    <div className="" style={{width: "30%"}}>
                                      <div
                                        className="img-shield p-2 m-2"
                                        style={{
                                          backgroundColor:
                                            "rgba(86, 204, 242, 0.4)",
                                          width: "120px",
                                        }}
                                      >
                                        {item?.vehicleModelName === "Hiace" ||
                                        item?.vehicleModelName === "Hiace X" ? (
                                          <img
                                            src={hiaceBus}
                                            alt="Jetmover 1"
                                            width="25"
                                          />
                                        ) : item?.vehicleModelName ===
                                          "Premium" ? (
                                          <img src={premium} alt="premium" />
                                        ) : item?.vehicleModelName ===
                                          "GIGM PRO" ? (
                                          <img src={proBus} alt="GIGM PRO" />
                                        ) : (
                                          <img src={jetBus} alt="Jetmover 1" />
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className=" align-self-center"
                                      style={{ padding: "0 30px", width: "50%" }}
                                    >
                                      <h1 className="select-h1">
                                        {item?.vehicleModelName}
                                      </h1>
                                      <p className="select-p">
                                        <span>Departure:</span>&nbsp;
                                        {getDepartureTerminalNameSplit(
                                          item?.routeName
                                        )}
                                        <span>•</span>
                                        &nbsp;<span>Arrival:</span>&nbsp;{" "}
                                        {getArrivalTerminalNameSplit(
                                          item?.routeName
                                        )}
                                      </p>
                                    </div>
                                    <div
                                      className="align-self-center"
                                      style={{ padding: "0 30px", width: "20%" }}
                                    >
                                      <div className=" text-center pr-5">
                                        <h1 className="select-amt" style={{fontSize: "18px" }}>
                                          ₦{nf.format(item?.amount)}
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        </div>
                      </section>
                    </>
                  )}
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default RoutePrices;
