import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import Busterminal from "../../src/views/Busterminal/busterminal";
import Faq from "../../src/views/FAQ/Faq";
import history from "../services/history";
import About from "../views/About/about";
import AwardsComponent from "../views/Awards/awards";
import BankTransferComponent from "../views/BankTransfer/bankTransferPayment";
import BookingStatusComponent from "../views/BookingStatus/index";
import BusHire from "../views/BusHire/bushire";
import AzaPayCallBackComponent from "../views/CallBackPage/azaPayCallBack";
import BudPayCallBackComponent from "../views/CallBackPage/budPayCallBack";
import FlutterCallBackComponent from "../views/CallBackPage/flutterCallBack";
import WovenFundWalletComponent from "../views/CallBackPage/fundWalletWoven";
import HireCallBackComponent from "../views/CallBackPage/hireCallBack";
import CallBackComponent from "../views/CallBackPage/index";
import NombaPayCallBackComponent from "../views/CallBackPage/nombaPayCallBack";
import WovenCallBackComponent from "../views/CallBackPage/wovenCallBack";
import PaymentComponent from "../views/CallBackPage/wovenTripPayment";
import CareerComponent from "../views/Career/career";
import Contact from "../views/ContactUs/contact";
import Enterprise from "../views/EnterprisePartner/enterprise";
import EntryComponent from "../views/EntryPage/index";
import PasswordComponent from "../views/ForgotPasswordPage/index";
import HireBusComponent from "../views/HireBus/index";
import HomeComponent from "../views/HomePage/index";
import LoginComponent from "../views/LoginPage/index";
import LoginTwoComponent from "../views/LoginPage/login";
import LoginThreeComponent from "../views/LoginPage/loginHire";
import Luggage from "../views/Luggages Allowance/index";
import NotFound from "../views/NotFoundPage/index";
import HireDetailsComponent from "../views/PassengerDetails/hireDetails";
import DetailsComponent from "../views/PassengerDetails/index";
import PayBudpayComponent from "../views/PayBudpay/payBudpay";
import PayFlutterComponent from "../views/PayFlutter/payFlutter";
import PayPaystackComponent from "../views/PayPaystack/payPaystack";
import Pickup from "../views/PickupLocation/pickup";
import RegisterComponent from "../views/RegisterPage/index";
import Safety from "../views/Safety/safety";
import BusSelectComponent from "../views/SelectBookingBus/index";
import SelfCheckingComponent from "../views/SelfChecking/selfChecking";
import SuggestedRouteComponent from "../views/SuggestedRoute/suggestedRoute";
import Terms from "../views/Terms/terms";
import Travels from "../views/Travel&tours";
import BillsPayment from "../views/UserProfile/BillsPayment";
// import AirtimeTopup from "../views/UserProfile/BillsPayment/Components/AirtimeTopup";
import { PayBillsCallBackComponent } from "../views/CallBackPage/paybillsCallBack";
import BookingHistory from "../views/UserProfile/Booking history";
import { DashboardComponent } from "../views/UserProfile/Dashboard/dashboard";
import HelpComponent from "../views/UserProfile/Help&Support/help";
import UserComponent from "../views/UserProfile/Profile/profile";
import ReferralComponent from "../views/UserProfile/Referral/referral";
import RescheduleComponent from "../views/UserProfile/Reschedule/reschedule";
import SettingComponent from "../views/UserProfile/Setting/setting";
import DataCollection from "../views/data-collection/data-collection";
import IsoComponent from "../views/iso/iso";
import PayBills from "../views/paybills";
import Privacy from "../views/privacy/privacy";
import TrackBusById from "../views/track-bus/track-bus";
import BnplCallBackComponent from "../views/CallBackPage/bnplCallBack";
import MoniepointPayCallBackComponent from "../views/CallBackPage/moniepointPayCallBack";
import LoginRegisterComponent from "../views/RegisterPage/login-reg";
import RoutePrices from "../views/Route Prices/route-prices";

const AllPages = () => (
  <Router history={history}>
    <Switch>
      <Route path="/dashboard" component={DashboardComponent} />
      <Route path="/entry" component={EntryComponent} />
      <Route path="/login" component={LoginComponent} />
      <Route path="/signin" component={LoginTwoComponent} />
      <Route path="/signnow" component={LoginThreeComponent} />
      <Route path="/Register" component={RegisterComponent} />
      <Route path="/login-reg" component={LoginRegisterComponent} />
      <Route exact={true} path="/" component={HomeComponent} />
      <Route path="/select-bus" component={BusSelectComponent} />
      <Route path="/passenger-details" component={DetailsComponent} />
      <Route path="/hire-passenger-details" component={HireDetailsComponent} />
      <Route path="/bnpl-call-back" component={BnplCallBackComponent} />
      <Route path="/call-back" component={CallBackComponent} />
      <Route path="/paybill-call-back" component={PayBillsCallBackComponent} />
      <Route path="/call-back-hire" component={HireCallBackComponent} />
      <Route path="/flutter-pay" component={FlutterCallBackComponent} />
      <Route path="/aza-pay" component={AzaPayCallBackComponent} />
      <Route path="/bud-pay" component={BudPayCallBackComponent} />
      <Route path="/aza-pay" component={AzaPayCallBackComponent} />
      <Route path="/nomba-pay" component={NombaPayCallBackComponent} />
      <Route path="/monnify-pay" component={MoniepointPayCallBackComponent} />
      <Route path="/woven-pay" component={WovenCallBackComponent} />
      <Route path="/wallet-woven-pay" component={WovenFundWalletComponent} />
      <Route path="/confirmed-wallet-payment" component={PaymentComponent} />
      <Route path="/select-bus-hire" component={HireBusComponent} />
      <Route path="/forgot-password" component={PasswordComponent} />
      <Route path="/profile" component={UserComponent} />
      <Route path="/setting" component={SettingComponent} />
      <Route path="/support" component={HelpComponent} />
      <Route path="/referral" component={ReferralComponent} />
      <Route path="/reschedule" component={RescheduleComponent} />
      <Route path="/enterprise" component={Enterprise} />
      <Route path="/About-Us" component={About} />
      <Route path="/Contact-Us" component={Contact} />
      <Route path="/Faq" component={Faq} />
      <Route path="/Bus-Terminal" component={Busterminal} />
      <Route path="/hire-vehicle" component={BusHire} />
      <Route path="/Privacy-Policy" component={Privacy} />
      <Route path="/Iso" component={IsoComponent} />
      <Route path="/Travels-Tours" component={Travels} />
      <Route path="/Safety" component={Safety} />
      <Route path="/paybills" component={PayBills} />
      <Route path="/bills-payment" component={BillsPayment} />
      {/* <Route path="/airtime-topup" component={AirtimeTopup}/> */}
      <Route path="/Terms-and-Conditions" component={Terms} />
      <Route path="/terms" component={Terms} />
      <Route path="/Luggage-Allowance" component={Luggage} />
      <Route path="/Pickupservice" component={Pickup} />
      <Route path="/bookinghistory" component={BookingHistory} />
      <Route path="/bookingStatus" component={BookingStatusComponent} />
      <Route path="/bankTransferPayment" component={BankTransferComponent} />
      <Route path="/suggest-route" component={SuggestedRouteComponent} />
      <Route path="/Awards" component={AwardsComponent} />
      <Route path="/Careers" component={CareerComponent} />
      <Route path="/selfcheckin" component={SelfCheckingComponent} />
      <Route path="/pay-flutter" component={PayFlutterComponent} />
      <Route path="/pay-paystack" component={PayPaystackComponent} />
      <Route path="/pay-budpay" component={PayBudpayComponent} />
      <Route path="/track-bus" component={TrackBusById} />
      <Route path="/data-collection" component={DataCollection} />
      <Route path="/route-prices" component={RoutePrices} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </Router>
);

export default AllPages;
