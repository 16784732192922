import React, { useEffect, useState } from "react";
import
// GooglePlacesAutocomplete,
{
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import Appinstall from "../../components/Appinstall/index";
import Footer from "../../components/Footer/index";
import GoogleMap from "../../components/GoogleMap";
import InputField from "../../components/InputField/index";
import Navbar from "../../components/NavBar/index";
import "./busterminal.css";
import { terminalData } from "./terminals.js";


const Busterminal = () => {
  const [terminal, setTerminal] = useState(terminalData);
  const [terminalLatLng, setTerminalLatLng] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    let newValue = e.target.value;
    const newTerminal = terminal;
    if (!newValue) {
      setTerminal(terminalData);
    } else {
      let obj = newTerminal.filter(
        (o) =>
          o.state.toLowerCase().includes(newValue.toLowerCase().trim()) ||
          o.terminal.toLowerCase().includes(newValue.toLowerCase().trim())
      );
      setTerminal(obj);
    }
    setValue(newValue);
  };

  const getTerminalLatLong = (address) => {
    // let newValue = item;
    // setValue(newValue.label);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0])
      )
      .then(({ lat, lng }) => {
        setTerminalLatLng({ lat, lng });
      });
  };
  return (
    <div>
      <Navbar />
      <section className="busterminal">
        <div className="container">
          <div className="terminals d-flex flex-wrap justify-content-between">
          <div className="terminal-scroll">
            <div className="col-md-6 col-sm-12">
              <div>
                <h1>Bus Terminals</h1>
                <div className="terminal-input">
                  <InputField
                    type="text"
                    value={value}
                    placeholder="Where is your destination?"
                    onChangeMethod={handleChange}
                  />
                </div>

                {/* button row */}
                {/* <div className="pl-3 row mt-3 terminal-btn">
                  <div className="mr-2 mt-2">
                    <Button
                      text="Most Popular"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 25px",
                      }}
                    />
                  </div>
                  <div className="mr-2 mt-2">
                    <Button
                      text="North"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 28px",
                      }}
                    />
                  </div>
                  <div className="mr-2 mt-2">
                    <Button
                      text="Lagos"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 28px",
                      }}
                    />
                  </div>
                  <div className="mr-2 mt-2">
                    <Button
                      text="South"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 28px",
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <Button
                      text="Edo"
                      handleButtonClick={() => {}}
                      type="button"
                      btnstyle={{
                        backgroundColor: "rgba(243, 246, 250, 0.8)",
                        color: "rgba(51, 51, 51, 0.7)",
                        border: "solid 1px rgba(86, 204, 242,0.5)",
                        padding: "0px 28px",
                      }}
                    />
                  </div>
                </div> */}
                {/* button row ends */}

                <div className="terminal-img-holder">
                  {terminal.map((terminals) => (
                    <div className="terminal-img-info">
                      <div className="terminal-section row row-grid mb-4" onClick={() => getTerminalLatLong(terminals.address) } style={{cursor:"pointer"}} key={terminals.id}>
                        <div className="terminal-map col-md-7 col-sm-12">
                          <div >
                            <img
                              src={terminals.logo}
                              alt=""
                              className="img-fluid"
                              style={{border: "2px solid #56CCF2", borderRadius: "10px"}}
                            />
                          </div>
                        </div>
                        <div className="terminal-details col-md-5 col-sm-12">
                          <div className="d-flex flex-column h-100 justify-content-around">
                            <div>
                              <h4>{terminals.terminal}</h4>
                              <p>{terminals.address}</p>
                              <div className="terminalline"></div>
                            </div>

                            <div>
                              <a href={`tel:${terminals.phoneNumber}`}><h6>{"0" + Number(terminals.phoneNumber)}</h6></a>
                              <a href={`{mailto: ${terminals.email}`}><h6>{terminals.email}</h6></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            </div>
            <div className="google-sticky">
              <div>
                <div>
                  <div className="wrapper" style={{marginTop:"70px"}}>
                    <br/>
                    <br/>
                    <GoogleMap value={terminalLatLng} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Appinstall />
      <Footer />
    </div>
  );
};

export default Busterminal;
