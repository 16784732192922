import React, { useEffect } from "react";
import Navbar from "../../components/NavBar/index";
import Footer from "../../components/Footer/index";
import "./career.css";

import career from "../../assets/img/career.png";

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <Navbar />
      <section className="career">
        <div className="career-top-section d-flex justify-content-center align-items-center">
          <div className="text-center">
            <h1 style={{ fontWeight: "bold" }} className="mb-4">
              We are on a mission. <br />
              Come join us.
            </h1>
            <p
              style={{ maxWidth: "600px", fontWeight: "500", fontSize: "14px" }}
            >
              We are working on redefining how people commute across Nigeria and
              West Africa. Being a technology driven business, we have developed
              some of the best Mobility Tech products to make it easy for people
              to do more.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="career-head-section d-flex align-items-center flex-wrap">
            <div className="head-section-text d-flex mt-1">
              <div className="align-self-center">
                <h1>
                  Be a part of the team <br /> building the future
                </h1>
                <br />
                <p className="">
                  We are always looking for smart individuals who want to join
                  force with us to continue to invent how we move from one place
                  to the other.
                  <br />
                  <br />
                  You’d work with cool people across different departments and
                  have the opportunity to make a dent on the world with the
                  impactful work we do here at GIGM.
                </p>
                <h4 className="mt-5" style={{ fontWeight: "550" }}>
                  So join us as we continue to build the future.
                </h4>
              </div>
            </div>
            <div className="head-section-image">
              <img
                src={career}
                alt=""
                className=""
                loading="lazy"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="jobs-section">
            <h4 className="text-center mb-3" style={{fontWeight:"600"}}>Available Jobs</h4>
            <p>There are no openings now. Check back later.</p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Career;
