import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/index";
import Navbar from "../../components/NavBar/index";
import "./about.css";
// import mission1 from "../../assets/img/mission1.png";
// import mission2 from "../../assets/img/mission2.png";
// import mission3 from "../../assets/img/mission3.png";
// import mission4 from "../../assets/img/mission4.png";
// import people from "../../assets/img/people.png";
// import rectangle from "../../assets/img/rectangle.png";
// import walletline from "../../assets/img/walletline.svg";
import about1 from "../../assets/img/about1.png";
import team6 from "../../assets/img/chibuzor.jpg";
import team4 from "../../assets/img/chuks.jpg";
import team7 from "../../assets/img/enaho.jpg";
import team1 from "../../assets/img/funmi.jpg";
import team5 from "../../assets/img/john.jpg";
import team2 from "../../assets/img/jude.jpg";
import team3 from "../../assets/img/ken.jpg";
// import news1 from "../../assets/img/news1.png";
import Button from "../../components/Button/index";
// import Loader from "../../components/Loader";
import { useHistory } from "react-router";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);
  // const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleClick = () => setClamped(!clamped);
  return (
    <div>
      <Navbar />
      <section className="about">
        <div className="container">
          <div className="head-section d-flex align-items-center flex-wrap">
            <div className="head-section-text d-flex mt-4">
              <div className="align-self-center">
                <h1>Redefining Mobility Across Africa</h1>
                <br />
                <p className={clamped && "clamp"}>
                  The desire to move people better is our biggest motivation. It
                  inspires us to reimagine mobility, innovate and provide you
                  with the ability to move freely and do easily. Our DNA is
                  centered around the concept of “human progress” and it is on
                  this premise we build products that simplify commuting,
                  provide opportunities for you to earn and live life on the
                  convenient side of things.
                  <br />
                  <br />
                  We are a technology-focused company that harnesses physical
                  and digital tools to move you from one place to the other and
                  remain resilient on our overarching goal to redefine mobility
                  across Africa, one country at a time. We think that mobility
                  should be hassle-free and will go the mile and extra to give
                  you this experience.
                  <br />
                  <br />
                  Plains and valleys have characterized our journey through
                  revolutionizing mobility, but you make it worth our while.
                  <br />
                  <br />
                  In our world, you are most important, and we have made it our
                  life mission as a team to relentlessly prioritize your
                  satisfaction, safety and progress by leveraging modern
                  technology.
                </p>
                {showButton && (
                  <p
                    style={{
                      cursor: "pointer",
                      color: "black",
                      // color: "#56CCF2",
                      fontWeight: "bold",
                    }}
                    onClick={handleClick}
                  >
                    Read {clamped ? "more" : "less"}
                  </p>
                )}
              </div>
            </div>
            <div className="head-section-image mb-5">
              <img
                src={about1}
                alt=""
                className=""
                loading="lazy"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
        <div className="the-team-section d-flex justify-content-center align-items-center">
          <div className="text-center">
            <h4 style={{ fontWeight: "bold" }} className="mb-3">
              The Team
            </h4>
            <p>
              We are a dynamic team of 800+ people on a mission to redefine
              mobility across Africa and passionate about connecting people to
              their families and loved ones. Interested in joining our team?{" "}
            </p>
            <Button
              handleButtonClick={() => {
                history.push("/careers");
              }}
              text="Explore Open Roles"
              type="button"
              // disabled={enabled}
              btnstyle={{
                backgroundColor: "#E21D00",
                // margin: "20px 0px",
                width: "140px",
              }}
            />
          </div>
        </div>
        <div className="our-leader-section d-flex justify-content-center align-items-center">
          <div className="container">
            <div
              className="text-center"
              id="our-teams"
              style={{ margin: "0 auto", wdith: "100%" }}
            >
              <h4 style={{ fontWeight: "bold" }} className="mb-3">
                Meet Our Leaders
              </h4>
              <p
                style={{
                  margin: "0 auto",
                  fontSize: ".8rem",
                  lineHeight: "22px",
                }}
              >
                Our leadership consists of intentional and professional
                individuals bound by a unified goal to make mobility easier.
              </p>
              <div className="about-image-section mt-5">
                <div className="image-contain">
                  <div
                    className=""
                    style={{
                      width: "240px",
                      height: "240px",
                      objectFit: "cover",
                      boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={team7}
                      alt="Enahoro Okhae"
                      className=""
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <h5 className="mt-3">
                    <b>Enahoro Okhae</b>
                  </h5>
                  <h6>Chief Executive Officer</h6>
                </div>
                <div className="image-contain">
                  <div
                    className=""
                    style={{
                      width: "240px",
                      height: "240px",
                      objectFit: "cover",
                      boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={team4}
                      alt="Chukwudi Anyanwuocha"
                      className=""
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <h5 className="mt-3">
                    <b>Chukwudi Anyanwuocha</b>
                  </h5>
                  <h6>Chief Enterprise Business Officer</h6>
                </div>

                <div className="image-contain">
                  <div
                    className=""
                    style={{
                      width: "240px",
                      height: "240px",
                      objectFit: "cover",
                      boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={team3}
                      alt="Kenneth Nwanganga"
                      className=""
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <h5 className="mt-3">
                    <b>Kenneth Nwanganga</b>
                  </h5>
                  <h6>Chief Technology Officer</h6>
                </div>
                <div
                // style={{ display: "block", width: "100%" }}
                >
                  <div className="image-contain">
                    <div
                      className=""
                      style={{
                        width: "240px",
                        height: "240px",
                        objectFit: "cover",
                        boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={team1}
                        alt="Funmilola Ogunleye"
                        className=""
                        loading="lazy"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <h5 className="mt-3">
                      <b>Funmilola Ogunleye</b>
                    </h5>
                    <h6>Chief Process Officer</h6>
                  </div>
                </div>
                <div className="image-contain">
                  <div
                    className=""
                    style={{
                      width: "240px",
                      height: "240px",
                      objectFit: "cover",
                      boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={team2}
                      alt="Jude Odum"
                      className=""
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <h5 className="mt-3">
                    <b>Jude Odum</b>
                  </h5>
                  <h6>Chief Operations Officer</h6>
                </div>
                <div className="image-contain">
                  <div
                    className=""
                    style={{
                      width: "240px",
                      height: "240px",
                      objectFit: "cover",
                      boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={team5}
                      alt="John Okafor"
                      className=""
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <h5 className="mt-3">
                    <b>John Okafor</b>
                  </h5>
                  <h6>Chief Procurement Officer</h6>
                </div>
                <div className="image-contain">
                  <div
                    className=""
                    style={{
                      width: "240px",
                      height: "240px",
                      objectFit: "cover",
                      boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={team6}
                      alt="Chibuzor Onwuameze"
                      className=""
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <h5 className="mt-3">
                    <b>Chibuzor Onwuameze</b>
                  </h5>
                  <h6>Head of Finance</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", padding: "50px 0", background: "#D4EEF8" }}
        >
          <div className="container">
            <div className="" style={{ margin: "0 auto", wdith: "100%" }}>
              <h4 style={{ fontWeight: "bold" }} className="mb-3 text-center">
                GIGM on the News
              </h4>

              <div className="about-image-section mt-5">
                <div className="news-contain">
                  <div
                    className=""
                    style={{
                      width: "70%",
                      height: "350px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={news1}
                      alt="Stella Ajaere"
                      className=""
                      loading="lazy"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <a
                    href="https://www.ripplesnigeria.com/gigm-strikes-another-first-as-company-app-integrates-bills-payment-others/"
                    className="news-para mt-3"
                  >
                    <b>
                      GIGM strikes another first, as company App integrates
                      bills payment, others
                    </b>
                  </a>

                  <h6 className="mt-2">December 8, 2021</h6>
                </div>
                <div className="news-contain">
                  <div
                    className=""
                    style={{
                      width: "70%",
                      height: "350px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={news1}
                      alt="Chidi Ajaere"
                      className=""
                      loading="lazy"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <a
                    href="https://www.pulse.ng/news/local/gig-mobility-expands-operations-to-ghana-with-new-terminals-in-accra/cz4qnj9"
                    className="news-para mt-3"
                  >
                    <b>
                      GIG Mobility expands operations to Ghana with new
                      terminals in Accra
                    </b>
                  </a>

                  <h6 className="mt-2">December 3, 2019</h6>
                </div>
                <div className="news-contain">
                  <div
                    className=""
                    style={{
                      width: "70%",
                      height: "350px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={news1}
                      alt="Uche Ajaere"
                      className=""
                      loading="lazy"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <a
                    href="https://techcabal.com/2021/12/09/gigm-makes-super-app-play/"
                    className=" news-para mt-3"
                  >
                    <b>
                      Nigeria’s GIGM makes super-app play with new e-wallet and
                      bills payment service
                    </b>
                  </a>
                  <h6 className="mt-2">9th December 2021</h6>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>

      <Footer />
    </div>
  );
};

export default About;
