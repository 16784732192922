import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import downarrow from "../../assets/img/downarrow.png";
// import Button from "../../components/Button";
import Footer from "../../components/Footer";
// import Loader from "../../components/Loader";
import Navbar from "../../components/NavBar";
import apiroutes from "../../services/apiroutes";
import { request, requestPayment } from "../../services/apiservice";
import { getAuth } from "../../services/auth";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
export const MoniepointPayCallBackComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [stateSuccessful, setStateSuccessful] = useState({});
  const [failureState, setFailureState] = useState(false);
  const [seatNumber, setSeatNumber] = useState([]);
  const [nombaRef, setNombaRef] = useState("");
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const reference = urlParams.get("paymentReference");
  const token = getAuth();
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  let routeName = stateSuccessful?.route || "loading... ==> loading...";
  const routeFields = routeName?.split("==>");
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("transEmailAmount"));
    // let reference = JSON.parse(localStorage.getItem("nombaRef"));
    setNombaRef(reference);
    setSeatNumber(data ? data?.seats : "");
    setDetails(data);
    // const confirmPayment = {
    //   RefCode: reference,
    // };
    requestPayment(apiroutes.ConfirmMoniePointPayment(reference), "get", null, token)
    .then((res) => {
    console.log(res, "NUGAGEE CALLBACK");

        if (
          res.data?.code == 1
        ) {
          setStateSuccessful(res?.data?.data);
          localStorage.removeItem("userSelect");
          localStorage.removeItem("allTripDetails");
          localStorage.removeItem("selectedBusData");
          localStorage.removeItem("selectedSeats");
          localStorage.removeItem("selectedSeatsReturn");
          localStorage.removeItem("selectedReturnBusData");
        } else {
          setFailureState(true);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: res?.data?.shortDescription,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err,
          icon: "error",
        });
      });
  }, [token]);
  var newDate = new Date(stateSuccessful.departureDate || "1/1/2000");
  var yearOne = newDate.getFullYear();
  var monthOne = newDate.getMonth() + 1; //getMonth is zero based;
  var dayOne = newDate.getDate();
  let formatted = dayOne + "-" + monthOne + "-" + yearOne;
  const getBookingDetails = (e) => {
    e.preventDefault();
    setLoading(true);
    request(apiroutes.BookingStatus(nombaRef), "get", null, token)
      .then((res) => {
        setLoading(false);
        localStorage.setItem(
          "allBookingStatus",
          JSON.stringify(res.data.Object)
        );
        history.push("/bookingStatus");
      })
      .catch((err) => {
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err,
          icon: "error",
        });
        setLoading(false);
      });
  };
  return (
    <div>
      <Navbar />
      <section className="suc-err-page">
        {failureState ? (
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-10 col-md-8 col-lg-6">
                <div className="ticket-card">
                  <h1 className="route-card-h1">
                    Your Booking was Cancelled!!!
                  </h1>
                  <div className="text-center">
                    <button
                      className="download-btn"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Book Again &nbsp; <img src={downarrow} alt="" />
                    </button>
                  </div>
                </div>
                {/* <h3>Reference Code: {reference}</h3> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-10 col-md-8 col-lg-6">
                <div className="ticket-card">
                  <h1 className="route-card-h1">Your Ticket ({nombaRef})</h1>
                  <div className="route-card">
                    <div className="d-flex">
                      <p className="left-par">
                        Departure
                        <br />
                        <strong>{routeFields[0] || stateSuccessful?.departureTerminal}</strong>
                        {/* <strong>{stateSuccessful?.departureTerminal}</strong> */}
                      </p>
                      <p className="left-par ml-auto text-right">
                        Destination
                        <br />
                        <strong>{routeFields[1] || stateSuccessful?.destinationTerminal}</strong>
                        {/* <strong>{stateSuccessful?.destinationTerminal}</strong> */}
                      </p>
                    </div>
                  </div>
                  <div className="route-card-blue">
                    <div className="d-flex">
                      <p className="left-par">
                        Departure date
                        <br />
                        <strong>{formatted}</strong>
                      </p>
                      <p className="left-par ml-auto text-right">
                        Departure Time
                        <br />
                        <strong>{stateSuccessful?.departureTime}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="route-card-white">
                    <div className="d-flex">
                      <p className="left-par">
                        Passenger
                        <br />
                        <span>{details?.mainName}</span>
                        <br />
                        {!details?.otherNames ||
                        details?.otherNames.length === 0 ? (
                          "&nbsp;"
                        ) : (
                          <>
                            {details?.otherNames.map((item, i) => (
                              <>
                                <span key={i}>{item?.fullName}</span>
                                <br />
                              </>
                            ))}
                          </>
                        )}
                      </p>
                      <p className="left-par ml-auto text-right">
                        Seats
                        <br />
                        <strong>{seatNumber?.toString()}</strong>
                      </p>
                    </div>
                  </div>
                  {/* <div className="route-card-white-b">
                     <div className="d-flex">
                       <p className="left-par">
                       Passenger
                         <br />
                         {stateSuccessful.DepartureDate}
                       </p>
                       <p className="left-par ml-auto text-right">
                       Seats
                         <br />
                         {stateSuccessful.SeatNumber}
                       </p>
                     </div>
                   </div> */}
                  <div className="text-center">
                  <button
                      className="download-btn"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Book Again &nbsp; <img src={downarrow} alt="" />
                    </button>
                    {/* <Button
                      handleButtonClick={getBookingDetails}
                      text={
                        loading ? (
                          <Loader dark={false} />
                        ) : (
                          "Check Booking Details"
                        )
                      }
                      type="button"
                      btnstyle={{
                        background: "#E21D00",
                        color: "white",
                        margin: "20px 0px",
                        fontFamily: "Euclid Circular B",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
};
export default MoniepointPayCallBackComponent;
