import React from "react";
import appleBtn from "../../assets/img/App Store - eng.png";
import googleBtn from "../../assets/img/Google Play - eng.png";
import mobileapp1 from "../../assets/img/phones.png";
import "./index.css";
// import { Link } from "react-router-dom";

const Appinstall = () => {
  return (
    <div>
      <section className="suggest-section">
        <div className="container">
          <div
            className="suggest-image"
          >
            <div
              className="suggest-image-app d-flex"
            >
              <img
                className=""
                src={mobileapp1}
                alt="mobile-app1"
                loading="lazy"
              />
              {/* <img
                className="app-image1"
                src={mobileapp1}
                alt="mobile-app1"
                loading="lazy"
              /> */}
              {/* <img
                className="app-image2"
                src={mobileapp2}
                alt="mobile-app2"
                loading="lazy"
              /> */}
            </div>
            <div className="suggest-text">
              <h1>Move Freely, Do Easily</h1>
              <p>
                It is all you need in one. Book bus tickets, hire a vehicle and
                pay bills.
              </p>
              <a href="https://onelink.to/j7k4zu">
                <img
                  src={googleBtn}
                  alt=""
                  className="download-btn-btn"
                  loading="lazy"
                />
              </a>
              &nbsp;
              <a href="https://onelink.to/j7k4zu">
                <img
                  src={appleBtn}
                  alt=""
                  className="download-btn-btn"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Appinstall;
