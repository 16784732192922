import React, { useState } from "react";
// import { formatDepartureDate } from "../../../components/util";

const Details = ({ onclick, details }) => {
  const [alternativeDate, setAlternativeDates] = useState(
    new Date(Date.UTC(2020, 11, 20, 3, 23, 16, 738))
  );
  const [showNooftickets, setShowNooftickets] = useState(false);

  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return (
    <div className="bookingdetails-parent">
      <section className="booking-history2">
        <div className="container">
          <div className="row row-grid">
            <div class="col-md-12 text-right">
              <i class="fa fa-times booking-time" onClick={onclick}></i>
            </div>
          </div>
          {/* <div className="row row-grid">
            <div className="col-md-12 text-center">
              <div className="profilepic-holder">
                <img
                  src={logo}
                  style={{ borderRadius: "50%" }}
                  alt="dummy"
                  width="80"
                  height="80"
                />
              </div>
              <h4>Captain: Kuti Demmah</h4>
              <h4>Vehicle number: 0134529542</h4>
            </div>
          </div> */}
        </div>
      </section>
      <br />

      <section>
        <div className="container">
          <div className="booking-history2-details-holder">
            <div className="booking-history2-details">
              <div className="row mb-4">
                <div className="col-sm-12 col-md-6 ">
                  <h2>Route</h2>
                </div>
                <div className="col-md-6">
                  <p>
                    <i class="fa fa-map-marker map" aria-hidden="true"></i>
                    {details?.departureTerminalName}
                  </p>
                  <p>
                    <i
                      class="fa fa-ellipsis-v dotted"
                      style={{ fontSize: "12px" }}
                    ></i>
                  </p>
                  <p>
                    <i class="fa fa-dot-circle-o location"></i>
                    {details?.destinationTerminalName}
                  </p>
                </div>
              </div>

              <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Booking</h2>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>
                      {details?.bookingStatus === 0 ? (
                        <p className="blink_me" style={{ color: "red" }}>
                          Pending
                        </p>
                      ) : details?.bookingStatus === 1 ? (
                        <p style={{ color: "#6AC28F" }}>Confirmed</p>
                      ) : (
                        <p style={{ color: "red" }}>Cancelled</p>
                      )}
                    </strong>
                  </p>
                </div>
              </div>

              {/* <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Ratings</h2>
                </div>
                <div className="col-md-6">
                  <p>
                    {details.tripRating === null ? (
                      <div>
                        <i class="fa fa-star pr-2" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star" aria-hidden="true" style={{color:"#FFD541"}}></i>
                      </div>
                    ) : (details.tripRating.averageRating === 1 ? (
                      <div>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star" aria-hidden="true" style={{color:"#FFD541"}}></i>
                      </div>
                    ) : details.tripRating.averageRating === 2 ? (
                      <div>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star" aria-hidden="true" style={{color:"#FFD541"}}></i>
                      </div>
                    ) : details.tripRating.averageRating === 3 ? (
                      <div>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star" aria-hidden="true" style={{color:"#FFD541"}}></i>
                      </div>
                    ):details.tripRating.averageRating === 4 ? (
                      <div>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    ):details.tripRating.averageRating === 5 ? (
                      <div>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star pr-2 rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                        <i class="fa fa-star rating" aria-hidden="true" style={{color:"#FFD541"}}></i>
                      </div>
                    ):null)}
                  </p>
                </div>
              </div> */}

              <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Ref code:</h2>
                </div>
                <div className="col-md-6">
                  <p>{details?.bookingReferenceCode}</p>
                </div>
              </div>

              <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Total amount</h2>
                </div>
                <div className="col-md-6">
                  <h4>₦{details?.totalAmount}</h4>
                </div>
              </div>

              <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Booking date</h2>
                </div>
                <div className="col-md-6">
                  <p>
                    {dateTimeFormat.format(
                      new Date(details?.createdDate || alternativeDate)
                    )}
                  </p>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <h2>Departure date</h2>
                </div>
                <div className="col-md-6">
                  <p>
                    {/* {formatDepartureDate(
                      details?.vehicleTripRegistrations.departureDate.day,
                      details?.vehicleTripRegistrations.departureDate.month,
                      details?.vehicleTripRegistrations.departureDate.year
                    )} */}
                    {dateTimeFormat.format(
                      new Date(details?.departureDate || alternativeDate)
                    )}
                  </p>
                </div>
              </div>

              <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Booking seat</h2>
                </div>
                <div className="col-md-6">
                  <p>{details?.seatNumber}</p>
                </div>
              </div>

              <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Contact info</h2>
                </div>
                <div className="col-md-6">
                  <p>{details?.fullName}</p>
                </div>
              </div>

              <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Contact Phone No.</h2>
                </div>
                <div className="col-md-6">
                  <p>{details?.phoneNumber}</p>
                </div>
              </div>

              <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Next of Kin</h2>
                </div>
                <div className="col-md-6">
                  <p>{details?.nextOfKinName}</p>
                </div>
              </div>

              {/* <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Trip Type</h2>
                </div>
                <div className="col-md-6">
                  <p>One Way Trip</p>
                </div>
              </div> */}

              <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>Passenger type</h2>
                </div>
                <div className="col-md-6">
                  <p>{details?.passengerType === 0 ? "Adult" : "Child"}</p>
                </div>
              </div>
              <div className="row row-grid mb-4">
                <div className="col-md-6">
                  <h2>No. of Ticket</h2>
                </div>
                <div className="col-md-6">
                  {details?.noOfTicket == 1 ? (
                    <p>{details?.noOfTicket}</p>
                  ) : (
                    <p
                      onClick={() => {
                        setShowNooftickets(!showNooftickets);
                      }}
                    >
                      {details?.noOfTicket}{" "}
                      <span
                        style={{
                          cursor: "pointer",
                          color: showNooftickets ? "red" : "blue",
                        }}
                      >
                        {showNooftickets ? "close" : "view"}
                      </span>
                    </p>
                  )}
                </div>
              </div>
              {showNooftickets && (
                <div
                  className="booking-history2-details-holder"
                  style={{ padding: "10px" }}
                >
                  {details?.seatManagementDetails.map((item) => (
                    <>
                      <div
                        className="booking-history2-details mb-4"
                        style={{ padding: "15px" }}
                      >
                        <div className="row row-grid mb-4">
                          <div className="col-md-6">
                            <h2>Full Name</h2>
                          </div>
                          <div className="col-md-6">
                            <p>{item?.fullName}</p>
                          </div>
                        </div>
                        <div className="row row-grid mb-4">
                          <div className="col-md-6">
                            <h2>Ref code:</h2>
                          </div>
                          <div className="col-md-6">
                            <p>{item?.bookingReferenceCode}</p>
                          </div>
                        </div>
                        <div className="row row-grid mb-4">
                          <div className="col-md-6">
                            <h2>Booking seat:</h2>
                          </div>
                          <div className="col-md-6">
                            <p>{item?.seatNumber}</p>
                          </div>
                        </div>
                        <div className="row row-grid mb-4">
                          <div className="col-md-6">
                            <h2>Amount</h2>
                          </div>
                          <div className="col-md-6">
                            <h4>₦{item?.amount}</h4>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Details;
