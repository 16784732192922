import PropTypes from "prop-types";
import React from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "./index.css";

const ModalComponent = ({
  visible,
  handleClose,
  title,
  paragraph,
  body,
  width,
  height,
  modContainerClassName
}) => {
  return (
    <Rodal
      visible={visible}
      onClose={handleClose}
      width={width}
      height={height}
    >
      <div style={{ overflow: "hidden", height: "530px" }}>
        <div className={modContainerClassName || "modContainer"}>
          <div className="modalTitle">{title}</div>
          <div className="modalPar">{paragraph}</div>
          <div style={{ marginTop: "0px" }}>{body}</div>
        </div>
      </div>
    </Rodal>
  );
};

ModalComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number || PropTypes.string,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  paragraph: PropTypes.string,
  body: PropTypes.element.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalComponent;
