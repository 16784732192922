import React, { useEffect, useState } from "react";
import Alert from "../../components/Alert/index";
import Button from "../../components/Button/index";
import Select from "../../components/Dropdown/index";
import Expire from "../../components/Expire";
import Footer from "../../components/Footer/index";
import InputField from "../../components/InputField/index";
import Loader from "../../components/Loader";
import Navbar from "../../components/NavBar/index";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { getAuth } from "../../services/auth";
import "./contact.css";
import Swal from "sweetalert2";

// import { set } from "date-fns";

const Contact = () => {
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [feedback, setFeedback] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleChange(event) {
    setFeedback({
      ...feedback,
      [event.target.name]: event.target.value,
    });
  }

  const token = getAuth("access_token");

  const customerFeedback = (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setResponseMsg("Processing");
    const data = {
      CustomerName: feedback.name,
      CustomerPhoneNumber: feedback.phoneNumber,
      CustomerEmail: feedback.email,
      Category: category,
      Body: feedback.message,
      Subject: "Feedback from Website",
    };
    request(apiroutes.CustomerFeedback(), "post", data, token)
      .then((res) => {
        setLoading(false);
        setError("");
        setResponseMsg("We have Received your Feedback....Thank you");
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: "We have Received your Feedback....Thank you.",
          icon: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setTimeout(() => {
          setResponseMsg("");
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
        setResponseMsg("");
        setError(err.response);
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  };

  const genderOptions = [
    { id: 0, name: "Enquiry" },
    { id: 1, name: "Query" },
    { id: 2, name: "Emergency" },
    { id: 3, name: "Feedback" },
  ];
  const optionsGender = genderOptions.map((x) => ({
    label: x.name,
    value: x.id,
  }));

  const enabled = !(
    feedback.name &&
    feedback.phoneNumber &&
    feedback.email &&
    feedback.message &&
    category
  );

  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "black" : "black",
    }),
  };
  return (
    <div>
      <Navbar />
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="mb-2">Contact Us</h1>
              <p>
                We are always available to assist you. Our Omni-Channel contact
                centre agents are available 24/7 to support you.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="customercenter">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-6">
              {error && (
                <Expire delay={3000}>
                  <Alert
                    className="alert text-center alert-danger"
                    text={error}
                  />
                </Expire>
              )}
              {responseMsg && !error && (
                <Expire delay={3000}>
                  <Alert
                    className="alert text-center alert-primary"
                    text={responseMsg}
                  />
                </Expire>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-sm-12 offset-md-2">
              <div className="row row-grid">
                <div className="col-md-8 col-sm-12">
                  <div className="customercare">
                    <p>
                      Reach us on Stella our virtual assistant on{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="http://wa.me/+2349087616915"
                      >
                        <i class="fa fa-whatsapp" aria-hidden="true" ></i>{" "}
                      </a>{" "}
                      or on our social channels
                      <br/>
                      <span
                        className=""
                        
                      >
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://twitter.com/GIGMobility?s=09"
                        >
                          {" "}
                          <i
                            class="fa fa-twitter-square"
                            aria-hidden="true"
                          ></i>{" "}
                        </a>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.facebook.com/GIGMobility"
                        >
                          <i
                            class="fa fa-facebook-square"
                            aria-hidden="true"
                          ></i>{" "}
                        </a>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://instagram.com/gigmobility?utm_medium=copy_link"
                        >
                          {" "}
                          <i
                            class="fa fa-instagram"
                            aria-hidden="true"
                          ></i>{" "}
                        </a>
                      </span>
                    </p>
                    <p className="customercare-emailus mt-3">
                      You can also call us on <a style={{color:"white"}} href="tel:08139851110">08139851110</a> or send us an email on{" "}
                      <a href="mailto: contact@gigm.com"><span className="gigm-red">contact@gigm.com.</span></a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="customerform">
                    <h1>Customer Feedback</h1>
                    <br />
                    <div>
                      <label htmlFor="category">Category</label>
                      <Select
                        options={optionsGender}
                        value={category}
                        handleChange={(e) => setCategory(e.value)}
                        placeholder="Select Category"
                        styles={colorStyles}
                      />
                      <label htmlFor="name" className="mt-2">
                        Full Name
                      </label>
                      <InputField
                        type="text"
                        name="name"
                        value={feedback.name}
                        placeholder="Full Name"
                        onChangeMethod={handleChange}
                      />
                      <label htmlFor="phonenumber" className="mt-2">
                        Phone Number
                      </label>
                      <InputField
                        type="number"
                        name="PhoneNumber"
                        value={feedback.phoneNumber}
                        placeholder="phoneNumber"
                        onChangeMethod={handleChange}
                      />
                      <label htmlFor="email" className="mt-2">
                        Email Address
                      </label>
                      <InputField
                        type="email"
                        name="email"
                        value={feedback.email}
                        placeholder="Email"
                        onChangeMethod={handleChange}
                      />
                      <p className="mt-2">Message</p>
                      <textarea
                        rows="10"
                        value={feedback.message}
                        name="message"
                        onChange={handleChange}
                      ></textarea>
                      <Button
                        handleButtonClick={customerFeedback}
                        text={loading ? <Loader dark={false} /> : "Send"}
                        type="button"
                        disabled={enabled}
                        btnstyle={{
                          backgroundColor: "#E21D00",
                          margin: "20px 0px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
