import React from 'react'
import  "./index.css"

export default class Loader extends React.Component {
    render() {
        return <React.Fragment>
            {this.props.isLoading && <div class="kt-dialog kt-dialog--shown kt-dialog--default kt-dialog--loader kt-dialog--top-center">
                Loading ...
            </div>}
        </React.Fragment>
    }
}