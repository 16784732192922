import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import "./bushire.css";
// import PleaseNote from "../../components/pleasenote/note";
import vectorhirebus from "../../assets/img/Vector-hirebus.png";
import Appinstall from "../../components/Appinstall";
// import siennahirebus from "../../assets/img/sienna-hirebus.png";
import ellipsehirebus from "../../assets/img/Ellipse-44.svg";

function BusHire() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <section className="bushire">
        <div className="container">
          <div className="row pt-5">
            <div className="col-12">
              <h1 className="mb-2">
                We’ve got a travel solution for every need
              </h1>
              <br />
              <p>
                Some people like a little more exclusivity when they commute; we
                get that. Our hire service is an excellent choice for everyone
                looking to travel in maximum comfort and enjoy privacy while at
                it.
              </p>
              <br />

              {/* <div className="hirebus-btn">
                <Button
                  text="Register Now"
                  type="button"
                  btnstyle={{ backgroundColor: "#E21D00", margin: "20px 0px" }}
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="how-it-works">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>How it Works</h1>
            </div>
          </div>
          <br />
          <div className="row row-grid">
            <div className="col-md-4 col-sm-12">
              <div>
                <div className="vector-hirebus">
                  <img src={vectorhirebus} alt="" />
                </div>
                <br />
                <p>Select the hire option via the web or mobile app</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 ">
              <div>
                <div className="vector-hirebus">
                  <img src={vectorhirebus} alt="" />
                </div>
                <br />
                <p>Pick desired vehicle, input travel details and pay</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div>
                <div className="vector-hirebus">
                  <img src={vectorhirebus} alt="" />
                </div>
                <br />
                <p>
                  Get ready for a smooth ride from pick up point to the final
                  destination.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="availablebus">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div>
                <h1>Buses available for Hire</h1>
                <br />
                <p>
                  We have a large fleet of vehicles, and take pride in providing
                  the best charter services thus far. You can trust our services
                  as we provide you with different sized vehicle options to
                  cater to your travel needs – large or small. All vehicle hire
                  journeys are completed only by our highly trained captains,
                  our captains have years of experience driving and they are up
                  on their safety measures.
                </p>
                <br />
                <h3>The available buses are;</h3>
                <br />
                <ul>
                  <li>Toyota Hiace Bus</li>
                  <li>Toyota Sienna Minivans</li>
                  <li>Mercedes Benz Sprinter Bus</li>
                  <li>Luxury Prime Buses</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 align-self-center offset-md-1">
              <div>
                <img src={siennahirebus} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="how-it-works">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Things you need to know</h1>
            </div>
          </div>
          <br />
          <br />
          <div>
            <div className="row row-grid">
              <div className="col-md-6 col-sm-12">
                <div>
                  <div>
                    <h5>
                      <img src={ellipsehirebus} alt="" className="pr-3" />
                      Payment Terms
                    </h5>
                  </div>
                  <br />
                  <p className="other-things-text">
                    To confirm and secure your reservation, we typically require
                    full payments ahead of your trip.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div>
                  <div>
                    <h5>
                      <img src={ellipsehirebus} alt="" className="pr-3" />
                      Additional Charges
                    </h5>
                  </div>
                  <br />
                  <p className="other-things-text">
                    There are no additional charges. Our rates are inclusive of
                    all costs associated with your reservation.
                  </p>
                </div>
              </div>
            </div>
            <br />
            <br />

            <div className="row row-grid">
              <div className="col-md-6 col-sm-12">
                <div>
                  <div>
                    <h5>
                      <img src={ellipsehirebus} alt="" className="pr-3" />
                      Booking Cancellation
                    </h5>
                  </div>
                  <br />
                  <p className="other-things-text">
                    We take your hire reservation seriously and put in the work
                    to ensure you get value. We, however, understand that
                    sometimes there are unexpected circumstances that require
                    cancellation. Should the need arise, we deduct 20% of the
                    total amount paid for the hire service.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div>
                  <div>
                    <h5>
                      <img src={ellipsehirebus} alt="" className="pr-3" />
                      Emergencies
                    </h5>
                  </div>
                  <br />
                  <p className="other-things-text">
                    Your safety is our priority, and we do all within our means
                    to prioritize this. Our rapid response team is strategically
                    placed around the country to provide fast support in the
                    case of events that could disrupt your journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <PleaseNote /> */}
      <Appinstall />
      <Footer />
    </div>
  );
}

export default BusHire;
