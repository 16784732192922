import React, { useState } from "react";
import Swal from "sweetalert2";
import Button from "../../../components/Button/index";
import Select from "../../../components/Dropdown/index";
import InputField from "../../../components/InputField/index";

const CorporatePartner = ({ corporatenext }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [rcNo, setRcNo] = useState("");
  const [state, setState] = useState("");
  const [inspectLocation, setInspectLocation] = useState("");
  const [officialEmail, setOfficialEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [numberOfVehicles, setNumberOfVehicles] = useState("");

  const checkEmail = () => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(officialEmail)) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `Please provide a valid email address`,
        icon: "error",
      });
      return false;
    } else {
      handleEnterprise();
    }
  };

  const handleEnterprise = () => {
    if (
      !(firstName,
      lastName,
      businessName,
      businessAddress,
      rcNo,
      state,
      inspectLocation,
      officialEmail,
      phoneNo,
      numberOfVehicles)
    ) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: "All fields are required.",
        icon: "error",
      });
    } else if (phoneNo.length < 11 || phoneNo.length > 11) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: "Only Nigerian numbers are allowed. Please provide a Nigerian number.",
        icon: "error",
      });
    } else {
      const cooperatePartner_Details = {
        FirstName: firstName,
        LastName: lastName,
        BusinessName: businessName,
        businessAddress: businessAddress,
        CompanyRcNumber: rcNo,
        state: state,
        InspectionLocation: inspectLocation,
        PhoneNo: phoneNo,
        Email: officialEmail,
        CompanyName: "business",
        PartnerType: 0,
        NumberOfVehicles: numberOfVehicles,
      };
      localStorage.setItem(
        "cooperatePartner_Details",
        JSON.stringify(cooperatePartner_Details)
      );
      corporatenext();
    }
  };

  const stateOptions = [
    "FCT",
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ];
  const optionState = stateOptions.map((x) => ({ label: x, value: x }));

  const inspectLocationOptions = [
    "Jibowu - Lagos",
    "Ajah - Lagos",
    "Iyana Ipaja - Lagos",
    "Asaba - Delta",
    "Utako - FCT",
    "Uselu - Edo",
  ];
  const optionsInspectLocation = inspectLocationOptions.map((x) => ({
    label: x,
    value: x,
  }));

  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "white" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "black" : "black",
    }),
  };
  return (
    <div>
      <section>
        <div className="contactform">
          <div className="contactformholder">
            <div className="partnerform">
              {/* <h3 class="backicon"><i class="fa fa-arrow-left"></i>&nbsp; &nbsp;Back</h3> */}
              <h1>Contact Details</h1>
              <p>Kindly provide the details below</p>

              <div>
                <label htmlFor="firstName">First Name</label>
                <InputField
                  type="text"
                  placeholder="Enter First Name"
                  onChangeMethod={(e) => setFirstName(e.target.value)}
                />
                <label htmlFor="lastName">Last Name</label>
                <InputField
                  type="text"
                  placeholder="Enter Last Name"
                  onChangeMethod={(e) => setLastName(e.target.value)}
                />
                <label htmlFor="businessname">Business Name</label>
                <InputField
                  type="text"
                  placeholder="Enter Business Name"
                  onChangeMethod={(e) => setBusinessName(e.target.value)}
                />
                <label htmlFor="Businessaddress">Business Address</label>
                <InputField
                  type="text"
                  placeholder="Enter Address"
                  onChangeMethod={(e) => setBusinessAddress(e.target.value)}
                />
                <label htmlFor="officialEmail">Official Email</label>
                <InputField
                  type="email"
                  placeholder="Enter Valid Email Address"
                  onChangeMethod={(e) => setOfficialEmail(e.target.value)}
                />
                <label htmlFor="phoneNo">Phone No.</label>
                <InputField
                  type="tel"
                  placeholder="080X XXXX XXX"
                  onChangeMethod={(e) => setPhoneNo(e.target.value)}
                />
                <label htmlFor="rcNo">Company Rc Number</label>
                <InputField
                  type="text"
                  placeholder="Enter Company Rc No."
                  onChangeMethod={(e) => setRcNo(e.target.value)}
                />
                <label htmlFor="state">Select State</label>
                <Select
                  value={state}
                  options={optionState}
                  handleChange={(e) => setState(e.value)}
                  placeholder="Select State"
                  styles={colorStyles}
                />
                <label htmlFor="state">Select Preferred Inspect Location</label>
                <Select
                  value={inspectLocation}
                  options={optionsInspectLocation}
                  handleChange={(e) => setInspectLocation(e.value)}
                  placeholder="Select Inspection State"
                  styles={colorStyles}
                />
                <label htmlFor="state">Number Of Vehicles</label>
                <InputField
                  type="tel"
                  placeholder="Enter No. Of Vehicle"
                  onChangeMethod={(e) => setNumberOfVehicles(e.target.value)}
                />

                <Button
                  handleButtonClick={() => checkEmail()}
                  text="Next"
                  type="button"
                  btnstyle={{ backgroundColor: "#E21D00", margin: "20px 0px" }}
                  disabled={
                    !(
                      firstName &&
                      lastName &&
                      businessName &&
                      businessAddress &&
                      rcNo &&
                      state &&
                      inspectLocation &&
                      phoneNo &&
                      officialEmail &&
                      numberOfVehicles
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CorporatePartner;
