import React, { useEffect} from "react";
import "./setting.css";
import SideBar from "../SideBar/index";
import MobileNavBar from "../MobileNavbar/index";
import Navbar from "../../../components/NavBar/index";
import Editprofile from "./editprofile";
import Changepin from "./changepin";
import ChangeWalletPin from "./changewalletpin";
import ResetPin from "./Resetpin";
import Footer from "../../../components/Footer";
//import Button from "../../../components/Button";

const SettingComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="dashboardArea">
          <SideBar />
          <div className="contentarea">
            <MobileNavBar />
            <div style={{ backgroundColor: "#EBF4FA" }}>
              <div className="tabsSettings">
                <input
                  className="tab-input"
                  id="tab1"
                  type="radio"
                  name="tabs"
                  defaultChecked
                />
                <label htmlFor="tab1" className="tab-label">
                  Profile
                </label>
                <input
                  className="tab-input"
                  id="tab2"
                  type="radio"
                  name="tabs"
                />
                <label htmlFor="tab2" className="tab-label">
                  Change Password
                </label>
                <input
                  className="tab-input"
                  id="tab3"
                  type="radio"
                  name="tabs"
                />
                <label htmlFor="tab3" className="tab-label">
                  Change Wallet PIN
                </label>
                <input
                  className="tab-input"
                  id="tab4"
                  type="radio"
                  name="tabs"
                />
                <label htmlFor="tab4" className="tab-label">
                  Reset Wallet Pin
                </label>
                <div className="contentSettings">
                  <div id="tabContent1">
                    <Editprofile />
                  </div>

                  <div id="tabContent2">
                    <Changepin />
                  </div>

                  <div id="tabContent3">
                    <ChangeWalletPin />
                  </div>

                  <div id="tabContent4">
                    <ResetPin />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Button/> */}
      <Footer />
    </div>
  );
};

export default SettingComponent;
