import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import partner3 from "../../assets/img/ep.png";
import partner1 from "../../assets/img/ep1.png";
import partner2 from "../../assets/img/ep2.png";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import config from "../../configs";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { setPartnerAuth } from "../../services/auth";
import CorporateSubmitForm from "./CooperateSubmitForm/cooperatesubmitform";
import CorporatePartner from "./CorporatePartner/corporate";
import IndividualPartner from "./IndividualPartner/individual";
import Submitform from "./SubmitForm/submit";
import "./enterprise.css";
import Swal from "sweetalert2";

const Enterprise = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getToken()
    localStorage.removeItem("cooperatePartner_Details");
    localStorage.removeItem("individualPartner_Details");

  }, []);
  const [individual, setIndividual] = useState(true);
  const [corporate, setCorporate] = useState(false);
  const [submitDetails, setSubmitDetails] = useState(false);
  const [submitDetailsTwo, setSubmitDetailsTwo] = useState(false);

  const handleChange = () => {
    setIndividual(true);
    setCorporate(false);
    setSubmitDetails(false);
  };

  const handleChangeTwo = () => {
    setCorporate(true);
    setIndividual(false);
    setSubmitDetailsTwo(false);
  };

  const getToken = () => {
    const formEncoded = new URLSearchParams({
      username: config.USERNAME,
      password: config.PASSWORD,
      client_id: config.CLIENT_ID,
      grant_type: config.GRANT_TYPE,
      client_secret: config.CLIENT_SECRET,
    });

    request(apiroutes.GetToken(), "post", formEncoded)
      .then((res) => {
        const data = {
          access_token: res.data.access_token,
          expires_in: res.data.expires_in,
          profilePix: res.data.profilePix,
          roles: res.data.roles,
          user_id: res.data.user_id,
          userassignedmenu: res.data.userassignedmenu,
          username: res.data.username,
        };
        setPartnerAuth(data);
      })
      .catch((err) => {
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err,
          icon: "error",
        });
      });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // dots: true,
          autoplay: false,
        },
      },
    ],
  };

  //individual field to show next form
  const showSubmit = (e) => {
    setSubmitDetails(true);
    setIndividual(false);
  };

  //corporate field to show next form
  const showSubmitCoporate = (e) => {
    setSubmitDetailsTwo(true);
    setCorporate(false);
  };

  //go back to individual first form
  const goBack = (e) => {
    setSubmitDetails(false);
    setIndividual(true);
  };

  //go back to corporate first form
  const goBackTwo = (e) => {
    setSubmitDetailsTwo(false);
    setCorporate(true);
  };

  const individualContent = individual ? (
    <IndividualPartner next={showSubmit} />
  ) : null;

  const corporateContent = corporate ? (
    <CorporatePartner corporatenext={showSubmitCoporate} />
  ) : null;

  const submitContent = submitDetails ? <Submitform prev={goBack} /> : null;

  const CorporateSubmit = submitDetailsTwo ? (
    <CorporateSubmitForm cooperate={goBackTwo} />
  ) : null;

  return (
    <div>
      <Navbar />

      <section className="enterprise">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="enterprise-partner">
                <h1>Extra income never hurt anyone</h1>
                <h2>Become an Enterprise Partner today!</h2>
                <br/>
                <h5><b>Choose a partner type</b></h5>
                <div className="partner-checkbox">
                  <input
                    type="checkbox"
                    checked={individual}
                    onChange={handleChange}
                  />
                  <label htmlFor="partner">Individual Partner</label>
                  <p>
                    You own a vehicle and want to drive it as a captain (Driver)
                    within GIGM
                  </p>

                  <input
                    type="checkbox"
                    checked={corporate}
                    onChange={handleChangeTwo}
                  />
                  <label htmlFor="partner">Corporate Partner</label>
                  <p>
                    You own a vehicle and want to put them for profitable use on
                    GIGM Platform
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="slider-holder">
                <Slider {...settings}>
                  <div>
                    <div className="slide-partner">
                      <img src={partner1} alt="" loading="lazy"/>
                    </div>
                  </div>
                  <div>
                    <div className="slide-partner">
                      <img src={partner2} alt="" loading="lazy"/>
                    </div>
                  </div>
                  <div>
                    <div className="slide-partner">
                      <img src={partner3} alt="" loading="lazy"/>
                    </div>
                  </div>
                  <div>
                    <div className="slide-partner">
                      <img src={partner2} alt="" loading="lazy"/>
                    </div>
                  </div>
                  {/* <div>
                    <div className="slide-partner">
                      <img src={partner1} alt="" loading="lazy"/>
                    </div>
                  </div> */}
                  {/* <div>
                    <div className="slide-partner">
                      <img src={partner2} alt="" loading="lazy"/>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      {individualContent}

      {corporateContent}
      {submitContent}
      {CorporateSubmit}
      <Footer />
    </div>
  );
};

export default Enterprise;
