import React, { useEffect, useState } from "react";
import logo from "../../assets/img/Layer x0020 1.png";
import bulletImg from "../../assets/img/bulleting 3.png";
import "./login.css";
// import google from "../../assets/img/google (1) 1.svg";
// import facebook from "../../assets/img/facebook.png";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import eye from "../../assets/img/eye-24-512.png";
import eyeHidden from "../../assets/img/invisible 2.png";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Expire from "../../components/Expire";
import InputField from "../../components/InputField";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import configs from "../../configs";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { getAuth, setAuth, setUser } from "../../services/auth";
import { decrypt, encrypt } from "../../components/util";

export const LoginComponent = () => {
  useEffect(async () => {
    window.scrollTo(0, 0);

    // const keyy = "2181967899134498";
    // const ivv = "2938667801123957";
    // const decry = "fNGj54YaBcm4DYeWDRq+w+aBKjm62erF081KIhP6NDDSMsCPwkvdsnkV+ZY9mAnLy+3TbIVzQQRk1iiwhHT9PTAEzLgvLZqsBCEyVNkLtkU="
    // const decryptedData = await decrypt(decry, keyy, ivv);
    // console.log(decryptedData, "NUGAGEE aaaaaaaa");
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordField, setPasswordField] = useState("password");
  const [modalVisible, setVisible] = useState(false);
  const [modalVisibleConfirm, setVisibleConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [forgotEmail, setForgotEmail] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const history = useHistory();
  const [verificationCode, setVerificationCode] = useState("");
  const [modalVisibleVerify, setVisibleVerify] = useState(false);

  const token = getAuth();

  // async function decrypt(cipherText, key, iv) {
  //   const enc = new TextEncoder();
  //   const keyData = enc.encode(key);
  //   const ivData = enc.encode(iv);

  //   const cryptoKey = await crypto.subtle.importKey(
  //     "raw",
  //     keyData,
  //     { name: "AES-CBC" },
  //     false,
  //     ["decrypt"]
  //   );

  //   const encryptedData = Uint8Array.from(atob(cipherText), (c) =>
  //     c.charCodeAt(0)
  //   );
  //   const decrypted = await crypto.subtle.decrypt(
  //     { name: "AES-CBC", iv: ivData },
  //     cryptoKey,
  //     encryptedData
  //   );

  //   const dec = new TextDecoder();
  //   return JSON.parse(dec.decode(decrypted));
  // }

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
    setPasswordField(passwordField === "text" ? "password" : "text");
  };

  const toggleModal = () => {
    setVisible(true);
  };
  const toggleModalCloseVerify = () => {
    setVisibleVerify(true);
  };

  // const resendVerification = () => {
  //   setVisibleConfirm(false);
  //   setVisible(true);
  // };

  const toggleModalClose = () => {
    setVisible(false);
  };

  const toggleModalCloseTwo = () => {
    setVisibleConfirm(false);
  };

  // const handleForgotPassword = (e) => {
  //   e.preventDefault();
  //   // setError("");
  //   // setResponseMsg("Processing");
  //   setLoading(true);
  //   const verify = {
  //     UserName: forgotEmail,
  //   };
  //   request(apiroutes.ForgotPassword(), "post", verify, token)
  //     .then((res) => {
  //       if (
  //         res.data.Code === null ||
  //         res.data.Object === null ||
  //         res.data.Object === null
  //       ) {
  //         setLoading(false);
  //         setResponseMsg(null);
  //         // setError(res.data.ShortDescription);
  //         Swal.fire({
  //           showConfirmButton: false,
  //           timer: 4000,
  //           text: `${res.data.ShortDescription}`,
  //           icon: "error",
  //         });
  //         // setTimeout(() => setError(""), 4000)
  //       } else {
  //         setLoading(false);
  //         // setResponseMsg("Verification Code Sent");
  //         Swal.fire({
  //           showConfirmButton: false,
  //           timer: 4000,
  //           text: `Verification Code Sent`,
  //           icon: "success",
  //         });
  //         setVisible(false);
  //         setVisibleConfirm(true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.response);
  //       setResponseMsg(null);
  //       setLoading(false);
  //     });
  // };

  const handleSendOtp = () => {
    // setError("");
    // setResponseMsg("Processing");
    setLoading(true);
    const verify = {
      username: forgotEmail,
      activationCode: verificationCode,
    };
    request(apiroutes.SendOtp(), "post", verify, token)
      .then((res) => {
        if (res.data.code === 1 || res.data.code === "1") {
          setLoading(false);

          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            // text: `${res.data.shortDescription}`,
            text: `OTP Sent Successfully.`,
            icon: "success",
          });

          setVisibleVerify(true);
          setVisible(false);

          // setTimeout(() => setError(""), 4000)
        } else {
          setLoading(false);
          setResponseMsg(null);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${res.data.shortDescription}`,
            icon: "error",
          });
          setVisible(true);
          // setVisibleConfirm(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `${err.message}`,
          icon: "error",
        });
      });
  };

  const handleNavigateForgetPassword = () => {
    setLoading(true);
    const verify = {
      username: forgotEmail,
      activationCode: verificationCode,
    };
    localStorage.setItem("forgotPasswordPayload", JSON.stringify(verify));
    setTimeout(() => history.push("/forgot-password"), 3000);
  };

  const verifyCode = (e) => {
    e.preventDefault();
    // setResponseMsg("Processing");
    setLoading(true);
    const verifyDetails = {
      activationCode: verificationCode,
      username: forgotEmail,
      isAccountConfirmation: false,
    };
    request(apiroutes.VerifyOtp(), "post", verifyDetails, token)
      .then((res) => {
        if (res.data.code == 1 || res.data.code == -1) {
          setLoading(false);
          // setResponseMsg("Phone Number Verified");
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `OTP Verified.`,
            // text: `${res.data.shortDescription}`,
            icon: "success",
          });
          setVisibleVerify(false);
          setVisible(false);
          localStorage.setItem("forgotEmail", JSON.stringify(forgotEmail));
          toggleModalConfirm();

          // const data = {
          //   Title: res.data.data.title,
          //   Email: res.data.data.email,
          //   FirstName: res.data.data.firstName,
          //   LastName: res.data.data.lastName,
          //   Gender: res.data.data.gender,
          //   Image: res.data.data.customerPhoto,
          //   IsConfirmed: res.data.data.accountIsConfirmed,
          //   Token: res.data.data.jwtToken,
          //   NextOfKinName: res.data.data.NextOfKinName,
          //   NextOfKinPhone: res.data.data.NextOfKinPhone,
          //   PhoneNumber: res.data.data.phoneNumber,
          // };
          // setUser(data);
          // setAuth(res.data.data.jwtToken);
          // setTimeout(() => history.push("/"), 1500);

          // setError(res.data.ShortDescription);
          // setTimeout(() => setError(""), 4000)
        } else {
          setLoading(false);
          setResponseMsg(null);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            // text: `Password Doesn't Match`,
            text: `${res.data.shortDescription}`,
            icon: "error",
          });
          setVisibleVerify(true);
          setVisible(false);
        }
      })
      .catch((err) => {
        setResponseMsg(null);
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `${err.message}`,
          icon: "error",
        });
      });
  };

  const toggleModalConfirm = () => {
    // e.preventDefault();
    setVisibleConfirm(false);
    history.push("/forgot-password");
  };

  // const  checkEmail = () => {
  //   var filter =
  //     /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  //   if (!filter.test(email)) {
  //     Swal.fire({
  //       showConfirmButton: false,
  //       timer: 4000,
  //       text: `Please provide a valid email address`,
  //       icon: "error",
  //     });
  //     return false;
  //   } else {
  //     handleSubmit()
  //   }
  // }
  // const  checkEmailForgot = () => {
  //   var filter =
  //     /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  //   if (!filter.test(phoneNumber)) {
  //     Swal.fire({
  //       showConfirmButton: false,
  //       timer: 4000,
  //       text: `Please provide a valid email address`,
  //       icon: "error",
  //     });
  //     return false;
  //   } else {
  //     handleForgotPassword()
  //   }
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");
    // setResponseMsg("Processing");
    setLoading(true);
    const loginDetails = {
      userName: email,
      password: password,
      deviceToken: "string",
      countryId: 1,
      clientId: configs.USERNAME,
    };

    const key = configs.ENCRYPT_KEY;
    const iv = configs.ENCRYPT_IV;

    const { ciphertext } = await encrypt(loginDetails, key, iv);

    const loginPayload = { credentials: ciphertext };

    request(apiroutes.Login(), "post", loginPayload)
      .then(async (res) => {
        const decryptedData = await decrypt(res.data.data, key, iv);

        if (decryptedData?.Code == 1) {
          if (decryptedData?.Data?.CustomerStage != 0) {
            setLoading(false);
            setResponseMsg("Logged In Successfully");

            const data = {
              Title: decryptedData?.Data?.Title,
              Email: decryptedData?.Data?.Email,
              FirstName: decryptedData?.Data?.FirstName,
              LastName: decryptedData?.Data?.LastName,
              Gender: decryptedData?.Data?.Gender,
              Image: decryptedData?.Data?.CustomerPhoto,
              IsConfirmed: decryptedData?.Data?.AccountIsConfirmed,
              Token: decryptedData?.Data?.JwtToken,
              RefreshToken: decryptedData?.Data?.RefreshToken,
              NextOfKinName: decryptedData?.Data?.NextOfKinName,
              NextOfKinPhone: decryptedData?.Data?.NextOfKinPhone,
              PhoneNumber: decryptedData?.Data?.PhoneNumber,
              CustomerStage: decryptedData?.Data?.CustomerStage,
              CustomersId: decryptedData?.Data?.CustomersId,
            };
            setUser(data);
            setAuth(decryptedData?.Data?.JwtToken);
            history.push("/");
          } else {
            const data = {
              Title: decryptedData?.Data?.Title,
              Email: decryptedData?.Data?.Email,
              FirstName: decryptedData?.Data?.FirstName,
              LastName: decryptedData?.Data?.LastName,
              Gender: decryptedData?.Data?.Gender,
              Image: decryptedData?.Data?.CustomerPhoto,
              IsConfirmed: decryptedData?.Data?.AccountIsConfirmed,
              Token: decryptedData?.Data?.JwtToken,
              RefreshToken: decryptedData?.Data?.RefreshToken,
              NextOfKinName: decryptedData?.Data?.NextOfKinName,
              NextOfKinPhone: decryptedData?.Data?.NextOfKinPhone,
              PhoneNumber: decryptedData?.Data?.PhoneNumber,
              CustomerStage: decryptedData?.Data?.CustomerStage,
              CustomersId: decryptedData?.Data?.CustomersId,
            };
            setUser(data);
            setAuth(decryptedData?.Data?.JwtToken);
            Swal.fire({
              showConfirmButton: false,
              timer: 4000,
              text: `Please kindly complete your registration process.`,
              icon: "success",
            });
            history.push("/login-reg");
          }
        } else {
          setLoading(false);
          setResponseMsg(null);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${decryptedData?.ShortDescription}`,
            icon: "error",
          });
          // setError(res.data.ShortDescription);
          // setTimeout(() => setError(""), 4000)
        }
      })
      .catch((err) => {
        console.log(err.message);
        setResponseMsg(null);
        setLoading(false);
      });
  };

  // // Example usage
  // (async () => {
  //   const key = "2181967899134498";
  //   const iv = "2938667801123957";

  //   const data = {
  //     username: "john_doe",
  //     email: "john@example.com",
  //     roles: ["admin", "editor"],
  //   };

  //   // Encrypt the JSON object and return a string
  //   const { ciphertext } = await encrypt(data, key, iv);
  //   console.log("Encrypted (Base64):", ciphertext);

  //   // Decrypt the string back into a JSON object
  //   const decryptedData = await decrypt( ciphertext, key, iv);
  //   console.log("Decrypted JSON:", decryptedData);
  // })();

  const width = 500;
  const height = 350;
  const heightt = 450;
  const modalTitle = "Forgot Password?";
  const modalPar =
    "Please type in the email address linked to your GIGM account";
  const modalBody = (
    <div>
      <label htmlFor="email" className="label-auth">
        Email
      </label>
      <InputField
        type="text"
        placeholder="Email"
        onChangeMethod={(e) => setForgotEmail(e.target.value)}
      />
      <br />
      <br />
      <Button
        // text={loading ? <Loader dark={false} /> : "Get Verification Code"}
        text={
          loading ? (
            <div
              style={{
                display: "flex",
                margin: "0 auto",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                transform: "translateX(-10px)",
              }}
            >
              <Loader dark={false} />{" "}
              <span
                style={{
                  display: "inline-block",
                  transform: "translateX(-40px)",
                }}
              >
                Processing
              </span>
            </div>
          ) : (
            "Get Verification Code"
          )
        }
        // handleButtonClick={checkEmailForgot}
        handleButtonClick={handleSendOtp}
        disabled={!forgotEmail}
        type="button"
      />
    </div>
  );

  const modalTitleTwo = "Verification code";
  const modalParTwo =
    "Hi there, we just sent a verification code to your provided email";
  // const modalBodyTwo = (
  //   <div className="text-center">
  //     <br />
  //     <p>
  //       Didn’t get verification code?{" "}
  //       <span
  //         onClick={handleForgotPassword}
  //         // onClick={resendVerification}
  //         style={{ color: "#56CCF2", cursor: "pointer" }}
  //       >
  //         Resend
  //       </span>
  //     </p>
  //     <br />
  //     <br />
  //     <Button
  //       text="Click To Reset Your Password"
  //       // text="Get Verification Code"
  //       handleButtonClick={toggleModalConfirm}
  //       type="button"
  //     />
  //   </div>
  // );

  const modalTitleVerify = "Enter Verification Code";
  const modalParVerify =
    "Please check your email and enter the Verification Code.";
  const modalBodyVerify = (
    <div>
      <label htmlFor="email" className="label-auth">
        Verification Code
      </label>
      <InputField
        type="text"
        placeholder="Verification code"
        onChangeMethod={(e) => setVerificationCode(e.target.value)}
      />
      <br />
      <div className="text-center">
        <br />
        <p>
          Didn’t get verification code?{" "}
          <span
            onClick={handleSendOtp}
            // onClick={resendVerification}
            style={{ color: "#56CCF2", cursor: "pointer" }}
          >
            Resend
          </span>
        </p>
        <br />
      </div>
      <br />
      <Button
        // text={loading ? <Loader dark={false} /> : "Proceed"}
        text={
          loading ? (
            <div
              style={{
                display: "flex",
                margin: "0 auto",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                transform: "translateX(-10px)",
              }}
            >
              <Loader dark={false} />{" "}
              <span
                style={{
                  display: "inline-block",
                  transform: "translateX(-40px)",
                }}
              >
                Processing
              </span>
            </div>
          ) : (
            "Proceed"
          )
        }
        handleButtonClick={handleNavigateForgetPassword}
        // handleButtonClick={verifyCode}
        disabled={!verificationCode}
        type="button"
      />
    </div>
  );

  return (
    <div>
      <Modal
        width={width}
        height={heightt}
        visible={modalVisibleVerify}
        title={modalTitleVerify}
        paragraph={modalParVerify}
        body={modalBodyVerify}
        handleClose={toggleModalCloseVerify}
      />
      <Modal
        width={width}
        height={height}
        visible={modalVisible}
        title={modalTitle}
        paragraph={modalPar}
        body={modalBody}
        handleClose={toggleModalClose}
      />

      {/* <Modal
        width={width}
        height={height}
        visible={modalVisibleConfirm}
        title={modalTitleTwo}
        paragraph={modalParTwo}
        body={modalBodyTwo}
        handleClose={toggleModalCloseTwo}
      /> */}

      <div className="d-md-flex h-md-100">
        <div className="col-md-5 bg-indigo h-md-100">
          <div className="row">
            <div className="col-md-12">
              <div className="this-position">
                <Link to="/">
                  <img src={logo} alt="" className="home-logo" />
                </Link>
                <h1>Revolutionizing road transportation in Africa</h1>
                <p>
                  GIGM is a technologically powered mobility platform providing
                  MOBILITY services to people across Africa
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 h-md-100">
          <div className="d-md-flex align-items-center justify-content-center h-md-100">
            <div className="pt-0">
              <div className="auth-section">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    {error && (
                      <Expire delay={3000}>
                        <Alert
                          className="alert text-center alert-danger"
                          text={error}
                        />
                      </Expire>
                    )}
                    {responseMsg && !error && (
                      <Expire delay={3000}>
                        <Alert
                          className="alert text-center alert-primary"
                          text={responseMsg}
                        />
                      </Expire>
                    )}
                  </div>
                </div>
                <h1>Sign into your GIGM account using</h1>
                {/* <div className="text-center">
                  <button className="btn-social">
                    <img src={google} alt="" />
                    &nbsp; &nbsp; Google
                  </button>
                  &nbsp; &nbsp;
                  <button className="btn-social">
                    <img src={facebook} alt="" />
                    &nbsp; &nbsp; Facebook
                  </button>
                </div>
                <span className="breaking-or">Or</span> */}
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="email" className="label-auth">
                      Email / Phone Number
                    </label>
                    <InputField
                      type="email"
                      placeholder="Example@gmail.com"
                      onChangeMethod={(e) => setEmail(e.target.value)}
                      value={email}
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="password" className="label-auth">
                      Password
                    </label>
                    <div className="pass-wrapper">
                      <InputField
                        type={passwordField}
                        placeholder="Enter Your Password"
                        onChangeMethod={(e) => setPassword(e.target.value)}
                        value={password}
                        autoComplete="new-password"
                      />

                      {showPassword ? (
                        <img
                          src={eye}
                          alt=""
                          onClick={showPasswordToggle}
                          className="passwordVisible img-size"
                        />
                      ) : (
                        <img
                          src={eyeHidden}
                          alt=""
                          onClick={showPasswordToggle}
                          className="passwordVisible"
                        />
                      )}
                    </div>
                    <p className="forgot-password" onClick={toggleModal}>
                      Forgot Password?
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                    <Button
                      text={loading ? <Loader dark={false} /> : "Sign In"}
                      handleButtonClick={handleSubmit}
                      // handleButtonClick={checkEmail}
                      type="button"
                      disabled={!(email && password)}
                    />
                    <p className="forgot-password">
                      Don't have an Account? <Link to="/register">Sign Up</Link>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 offset-md-3">
                    <h2>Why sign up on GIGM</h2>
                    <ul>
                      <li>
                        <img
                          src={bulletImg}
                          alt="bullet-img"
                          height="15"
                          className=""
                        />
                        Get Additional 5% off
                      </li>
                      <li>
                        {" "}
                        <img
                          src={bulletImg}
                          alt="bullet-img"
                          height="15"
                          className=""
                        />
                        Manage your booking
                      </li>
                      <li>
                        {" "}
                        <img
                          src={bulletImg}
                          alt="bullet-img"
                          height="15"
                          className=""
                        />
                        Get exclusive deals and offers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginComponent;
