const makeAPICallAzaPay = async (
    { method = "POST", payload = null },
    ...customConfigs
  ) => {
    const headers = {
      "Accept": "application/json",
      "Content-type": "application/json",
      "Access-key": "D2LvO7tkMdRCHavz3MSeszF30Rv6WAFyJrJh0VgZPd9vysu8OV_gigm_aza",
      // "Access-key": "cjM6hkXNSPjZaLcbPPK1ap8aU6dkaloP1fHW5k13u9qxHXAOes_GIGMTest_aza",
      "Access-Control-Allow-Origin": "*",
      // "Content-type": "application/x-www-form-urlencoded",
      "Origin": "",
      "X-Requested-With": "",
    };
  
    const configs = {
      method,
      headers,
      ...customConfigs,
    };
  
    if (payload) configs.body = JSON.stringify(payload);
  
    return window
      .fetch("https://api.azapay.ng/api/v1/merchant/paymentgateway/initiate/timed/acc/tnx", configs)
      .then((response) => response.json())
      .catch((err) => err);
  };
  
  export default makeAPICallAzaPay;